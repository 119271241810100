<template>
    <section :id="cmsBlock.anchorTag" :class="cmsBlock.quoteStyle" class="component-container">
        <blockquote class="grey">
            <div class="quote-content">
                <div v-html="md(localize(cmsBlock.text))"></div>
                <span>— {{ localize(cmsBlock.author) }}</span>
            </div>
        </blockquote>
    </section>
</template>

<script>
    export default {
        name: 'QuoteBlock',

        props: [
            'cmsBlock'
        ]
    }
</script>

<style lang="scss" scoped>
    .component-container {
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');
        @include breakpoint('tablet') {
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        }
        @include breakpoint('mobile') {
            padding: 0 0;
        }
        blockquote {
            quotes: '„' '“' '‘' '’';
            font-style: getFontStyle('quoteText');
            font-weight: getFontWeight('quoteText');
            font-size: getFontSize('quoteText', 'desktop');
            font-family: getFontFamily('quoteText');
            position: relative;
            z-index: 1;
            pointer-events: none;
            border-left: none;
            width: 100%;
            //padding: 0 getSpacing('padding-default-horizontal', 'desktop');
            max-width: $container-width;
            margin: 0 auto;
            text-align: center;
            opacity: 0.8;

            @include breakpoint('tablet') {
                font-size: getFontSize('quoteText', 'tablet');
                //padding: 0 getSpacing('padding-default-horizontal', 'tablet');
                //width: calc(100% - 2 * getSpacing('padding-default-horizontal', 'tablet'));
            }
            @include breakpoint('mobile') {
                width: 100%;
                font-size: getFontSize('quoteText', 'mobile');
                //padding: 0 getSpacing('padding-default-horizontal', 'mobile');
            }

            .quote-content {
                padding: 0 getSpacing('padding-default-horizontal', 'desktop');
                @include breakpoint('tablet') {
                    padding: 0 getSpacing('padding-default-horizontal', 'tablet');
                }
                @include breakpoint('mobile') {
                    padding: 0 getSpacing('padding-default-horizontal', 'mobile');
                }
                &:before {
                    content: open-quote;
                    font-size: 80px;
                    display: block;
                    margin: 0 auto;
                    width: 22px;
                    padding: 10px;
                }
                &:after {
                    content: close-quote;
                    font-size: 80px;
                    display: block;
                    margin-left: -15px;
                    padding: 10px;
                }
            }

            span {
                margin-top: 0;
                margin-bottom: 10px;
                line-height: 1.2;
                font-size: getFontSize('quoteAuthor', 'desktop');

                @include breakpoint('tablet') {
                    font-size: getFontSize('quoteAuthor', 'tablet');
                }
                @include breakpoint('mobile') {
                    font-size: getFontSize('quoteAuthor', 'mobile');
                }
            }
        }
    }
</style>
